<template>
  <div class="bredcrumbs flex-row-start">
    <template v-for="(item, i) in items" :key="i">
      <template v-if="item != null">
        <p class="b22-600" @click="routeTo(item[1])">
          {{ item[0] }}
        </p>
        <div v-if="items.length != i + 1">/</div>
      </template>
    </template>
  </div>
</template>
<script>
export default {
  name: "TabsUI",
  props: ["items"],
  data() {
    return {
      activeItem: 0,
    };
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style lang="scss" scoped>
.bredcrumbs {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  color: #6b6a6a;
  margin-bottom: 40px;
  @media (max-width: $tablet + px) {
    gap: 5px;
    font-size: 14px;
    margin-bottom: 30px;
  }
}
p {
  cursor: pointer;
}
</style>